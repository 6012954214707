<template>
  <div id="labElementForm" class="form">
    <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="labElement.symbol"
        label="Symbol"
        :required="true"
      />
      <v-text-field
        v-model="labElement.element_name"
        label="Name"
        :required="false"
      />
      <v-text-field
        v-model="labElement.chinese_name"
        label="Chinese Name"
        :required="false"
      />
      <v-text-field
        v-model="labElement.thai_name"
        label="Thai Name"
        :required="false"
      />

      <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack"> Back </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
    </v-form>
  </div>
</template>

<script>
import { getLabElement } from "@/services/labElement";

import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      valid: false,
      labElement: {
        relations: {},
        symbol: "",
        element_name: "",
        chinese_name: "",
        thai_name: "",
      },
    };
  },
  created() {
    this.setInstance();
  },
  methods: {
    ...mapActions("labElement", ["createLabElement", "updateLabElement"]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    async handleSubmit() {
      if (this.id) {
        let labElement = this.labElement;
        delete labElement.relations;

        this.updateLabElement(labElement)
          .then(() => {
            this.addSuccessMessage("LabElement was updated successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error updating LabElement, please try again later"
            );
          });
      } else {
        let labElement = this.labElement;
        delete labElement.relations;

        this.createLabElement(labElement)
          .then(() => {
            this.addSuccessMessage("LabElement was created successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error creating LabElement, please try again later"
            );
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setInstance() {
      if (this.id) {
        this.labElement.id = this.id;
        getLabElement(this.id).then((response) => {
          let instance = response.data;
          for (let property in instance) {
            if (
              Object.prototype.hasOwnProperty.call(instance, property) &&
              Object.prototype.hasOwnProperty.call(this.labElement, property)
            ) {
              this.labElement[property] = instance[property];
            }
          }
          this.loaded = true;
        });
      } else {
        this.loaded = true;
      }
      this.setDependencies();
    },
    setDependencies() {},
  },
  components: {},
};
</script>
